import React from 'react';
import {
  Table as AntdTable,
  TableProps as OriginalProps,
  TableColumnsType,
} from 'antd';
import { DefaultRecordType } from 'rc-table/lib/interface';

export type TableProps<RecordType> = OriginalProps<RecordType>;
export type ColumnsType<RecordType> = TableColumnsType<RecordType>;

const { Column, ColumnGroup, Summary } = AntdTable;

export const CompoundedTable = { Column, ColumnGroup, Summary };

export const Table = <T extends DefaultRecordType>({
  ...props
}: TableProps<T>) => {
  return <AntdTable {...props} />;
};
