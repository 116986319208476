import type { ThemeConfig } from 'antd/es/config-provider';
import { shadeColor } from '../utils';

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const createRange = (color: string) => {
  return {
    LIGHTER: shadeColor(40, color) || '',
    LIGHT: shadeColor(20, color) || '',
    MAIN: color,
    DARK: shadeColor(-20, color) || '',
    DARKER: shadeColor(-40, color) || '',
    CONTRASTTEXT: '#fff' || '',
  };
};
const COMMON = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  PETROL: createRange('#004C4C'),
  GREEN: createRange('#2C947C'),
  BERRY: createRange('#990033'),
  BORDEAUX: createRange('#52001B'),
  BEIGE: createRange('#FFE7D6'),
  GRAY: createRange('#3F3F3F'),
};

const GRAY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  150: '#F7F8FA',
  200: '#F4F6F8',
  250: '#EAEDF0',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = COMMON.GREEN;

const SECONDARY = COMMON.BEIGE;

const INFO = {
  LIGHTER: '#D0F2FF',
  LIGHT: '#74CAFF',
  MAIN: '#393D47',
  DARK: '#0C53B7',
  DARKER: '#04297A',
  CONTRASTTEXT: '#fff',
};

const SUCCESS = {
  LIGHTER: '#E9FCD4',
  LIGHT: '#AAF27F',
  MAIN: '#54D62C',
  DARK: '#229A16',
  DARKER: '#08660D',
  CONTRASTTEXT: GRAY[800],
};

const WARNING = {
  LIGHTER: '#FFF7CD',
  LIGHT: '#FFE16A',
  MAIN: '#FFC107',
  DARK: '#B78103',
  DARKER: '#7A4F01',
  CONTRASTTEXT: GRAY[800],
};

const ERROR = {
  LIGHTER: '#FFE7D9',
  LIGHT: '#FFA48D',
  MAIN: '#FF4842',
  DARK: '#B72136',
  DARKER: '#7A0C2E',
  CONTRASTTEXT: '#fff',
};

const GRADIENTS = {
  PRIMARY: createGradient(PRIMARY.MAIN, PRIMARY.MAIN),
  INFO: createGradient(INFO.LIGHT, INFO.MAIN),
  SUCCESS: createGradient(SUCCESS.LIGHT, SUCCESS.MAIN),
  WARNING: createGradient(WARNING.LIGHT, WARNING.MAIN),
  ERROR: createGradient(ERROR.LIGHT, ERROR.MAIN),
};

const CHART_COLORS = {
  VIOLET: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  BLUE: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  GREEN: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  YELLOW: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  RED: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

export const PALETTE = {
  COMMON: { ...COMMON },
  PRIMARY: { ...PRIMARY },
  SECONDARY: { ...SECONDARY },
  INFO: { ...INFO },
  SUCCESS: { ...SUCCESS },
  WARNING: { ...WARNING },
  ERROR: { ...ERROR },
  GRADIENTS: GRADIENTS,
  CHART: CHART_COLORS,
  DIVIDER: GRAY[500],
  TEXT: { PRIMARY: '#707070', SECONDARY: GRAY[600], DISABLED: GRAY[400] },
  BACKGROUND: {
    PAPER: '#fff',
    DEFAULT: GRAY[100],
    NEUTRAL: GRAY[200],
    GRAY: GRAY[200],
  },
  GRAY,
};

export const FONTS = {
  FAMILY: 'Open Sans',
  HEADINGSIZE: 18,
  TEXTSIZE: 14,
};

export const createConfigTheme = (theme: typeof PALETTE): ThemeConfig => {
  const newTheme: ThemeConfig = {
    token: {
      colorPrimary: theme.PRIMARY.MAIN,
      colorSuccess: theme.SUCCESS.MAIN,
      colorWarning: theme.WARNING.MAIN,
      colorError: theme.ERROR.MAIN,
      colorInfo: theme.INFO.MAIN,
      colorTextBase: theme.TEXT.PRIMARY,
      colorBgBase: theme.COMMON.WHITE,
      fontSize: FONTS.TEXTSIZE,
      colorPrimaryBg: theme.COMMON.WHITE,
      colorPrimaryBgHover: theme.COMMON.BEIGE.LIGHTER,
      colorBorder: theme.GRAY[400],
      colorPrimaryHover: theme.PRIMARY.LIGHT,
      colorPrimaryTextHover: theme.PRIMARY.MAIN,
      colorPrimaryText: theme.COMMON.BLACK,
      colorPrimaryTextActive: theme.PRIMARY.MAIN,
      colorLink: theme.PRIMARY.DARKER,
      colorLinkHover: theme.PRIMARY.LIGHT,
      colorLinkActive: theme.PRIMARY.DARKER,
      ...theme,
    },
    components: {
      Radio: {
        colorPrimary: theme.PRIMARY.LIGHT,
      },
      Badge: {
        colorPrimary: theme.SUCCESS.MAIN,
      },
      Menu: {
        colorPrimary: theme.PRIMARY.MAIN,
        colorBgBase: theme.PRIMARY.MAIN,
      },
    },
  };

  return newTheme;
};

export const mergeTheme = (theme: Partial<typeof PALETTE>): typeof PALETTE => ({
  ...PALETTE,
  ...theme,
});
