import type { FC, MutableRefObject } from 'react';
import { PrinterOutlined, CheckSquareFilled } from '@ant-design/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePandoraContext, MapType } from '../../../context';
import { useOutsideAlerter } from '../../../hooks';
import FileSaver from 'file-saver';
import { MapHelper, TestID } from '../../../utils';

import * as S from './styles';
import { useTheme } from 'styled-components';

export interface ScreenshotButtonProps {
  mapRef?: MutableRefObject<MapType | undefined>;
  canvasId: string;
  type?: 'mtk' | 'mapbox';
}
export const ScreenshotButton: FC<ScreenshotButtonProps> = ({
  mapRef,
  canvasId,
  type = 'mapbox',
}) => {
  const theme = useTheme();
  const { messageApi } = usePandoraContext();
  const [key] = useState<number>(Math.random());
  const [imageSrc, setImageSrc] = useState<string>('');
  const [snapshotCapture, setSnapshotCapture] = useState<boolean>(false);
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  function timeout(ms: number | undefined) {
    return new Promise((res) => setTimeout(res, ms));
  }

  const openMessage = useCallback(() => {
    messageApi?.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      messageApi?.open({
        key,
        type: 'success',
        content: 'Snapshot Downloaded!',
        duration: 2,
      });
    }, 1000);
  }, [key, messageApi]);

  const warning = useCallback(() => {
    messageApi?.open({
      type: 'warning',
      content: 'You can now scroll the map !',
    });
  }, [messageApi]);

  const wrapperRef = useRef<HTMLButtonElement>(null);

  const onOutSideClick = () => {
    setSnapshotCapture((current) => {
      if (current) {
        warning();
      }
      return false;
    });
  };

  useOutsideAlerter(wrapperRef, onOutSideClick);

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onOutSideClick();
      }
    },
    [warning],
  );

  const handleSnapshotClick = () => {
    if (!snapshotCapture) {
      setSnapshotCapture(true);
    } else {
      setSnapshotCapture(false);
      handleOnClick();
    }
  };

  const handleOnClick = async () => {
    if (!mapRef?.current || !canvas) return;

    const mapCanvas = MapHelper.isMapMTK(mapRef?.current)
      ? mapRef.current.gl.getCanvas()
      : mapRef.current.getCanvas();
    const base64Image = mapCanvas?.toDataURL('image/png');

    if (canvas?.getContext) {
      const ctx = canvas?.getContext('2d');
      const img = new Image();
      if (base64Image) {
        img.src = base64Image;
        img.crossOrigin = 'anonymous';
      }

      img.onload = function () {
        if (ctx) {
          const alpha = 1.0;
          ctx.drawImage(img, 0, 0);

          ctx.font = `${(canvas.width * 2) / 100}px serif`;
          ctx.textAlign = 'end';
          ctx.fillStyle = 'rgba(33, 37, 41, ' + alpha + ')';
          ctx.fillText(
            `Copyright(C) ${new Date().getFullYear()} by iib-institut.de © OpenStreetMap contributors / Lizenz: ODbL | © Mapbox © OpenStreetMap`,
            canvas.width - 10,
            canvas.height - 10,
          );
        }
        const tempImageBase64 = canvas.toDataURL('image/png');

        setImageSrc(tempImageBase64);
      };
    } else {
      throw new Error('In else');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (imageSrc) {
        setSnapshotCapture(false);
        openMessage();
        await timeout(1500);
        fetch(imageSrc)
          .then((res) => res.blob())
          .then((blob) => FileSaver.saveAs(blob, 'IIB Snapshot/.png'))
          .then(() => setImageSrc(''));
      }
    }
    fetchData();
  }, [imageSrc]);

  return (
    <>
      <S.CustomButton
        className={`screenshot-button-${type}`}
        data-testid={TestID.ScreenshotButton}
        ref={wrapperRef}
        type="primary"
        icon={snapshotCapture ? <CheckSquareFilled /> : <PrinterOutlined />}
        onClick={handleSnapshotClick}
      />
      {snapshotCapture && (
        <div
          className="screenshot-overlay"
          data-testid={TestID.ScreenshotOverlay}
          style={{
            border: `5px dashed ${theme.PRIMARY.DARK}`,
            height: wrapperRef.current?.parentElement?.style.height
              ? `calc(${wrapperRef.current?.parentElement?.style.height} - 10px)`
              : 'calc(100% - 10px)',
            width: wrapperRef.current?.parentElement?.style.width
              ? `calc(${wrapperRef.current?.parentElement?.style.width} - 10px)`
              : 'calc(100% - 10px)',
            pointerEvents: 'none',
            position: 'absolute',
            left: '0',
            top: '0',
          }}
        ></div>
      )}
    </>
  );
};
