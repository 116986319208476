import React from 'react';
import { StyledDiv } from './datenschutz.styles';
import { PREECO_TOKEN } from '../../utils';

export const Datenschutz = () => {
  return (
    <StyledDiv>
      <div
        className="preeco-form"
        id="preeco-privacy-policy"
        data-type="privacy-policy"
        data-access-token={PREECO_TOKEN}
        data-locale="de-DE"
      >
        <h2>Datenschutzerklärung</h2>

        <p>
          Wir freuen uns, dass Sie unsere Website besuchen. Der Schutz und die
          Sicherheit Ihrer persönlichen Informationen bei der Nutzung unserer
          Website ist für uns sehr wichtig. Wir möchten Sie daher an dieser
          Stelle darüber informieren, welche Ihrer personenbezogenen Daten wir
          beim Besuch unserer Website erfassen und für welche Zwecke diese
          verwendet werden.
        </p>
        <p>
          Diese Datenschutzerklärung gilt für das Internetangebot der IIB
          Institut Innovatives Bauen GmbH, welches unter der Domain{' '}
          <a
            href="http://www.iib-institut.de/"
            target="_blank"
            rel="noreferrer noopener"
          >
            www.iib-institut.de
          </a>{' '}
          sowie den verschiedenen Subdomains und Produktwebseiten („unsere
          Website“) erreichbar ist. Dies umfasst unter anderem auch,
          wohnpreis.de, wohnlagenkarte.de, sowie alle Test- und Staging
          Umgebungen.
        </p>
        <p>Wer ist verantwortlich und wie erreiche ich Sie?</p>
        <p>
          Verantwortlicher für die Verarbeitung personenbezogener Daten im Sinne
          der EU-Datenschutz-Grundverordnung (DSGVO)
        </p>
        <p>
          IIB Institut Innovatives Bauen GmbH
          <br />
          Bahnhofsanlage 3<br />
          68723 Schwetzingen
          <br />
          (weitere Kontaktangaben vgl. unser Impressum auf dieser Webseite)
        </p>
        <p>
          <br />
          Datenschutzbeauftragte/r
          <br />
          Johns Datenschutz GmbH
          <br />
          An der Kolonnade 11
          <br />
          10117 Berlin
          <br />
          Sven Johns
          <br />
          <a href="mailto:office@datenschutz.immobilien">
            office@datenschutz.immobilien
          </a>
        </p>

        <h2>Worum geht es?</h2>

        <p>
          Diese Datenschutzerklärung erfüllt die gesetzlichen Anforderungen an
          die Transparenz bei der Verarbeitung personenbezogener Daten. Dies
          sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person beziehen. Hierzu gehören
          beispielsweise Informationen wie Ihr Name, Ihr Alter, Ihre Anschrift,
          Ihre Telefonnummer, Ihr Geburtsdatum, Ihre E-Mail-Adresse, Ihre
          IP-Adresse oder das Nutzerverhalten beim Besuch einer Website.
          Informationen, bei denen wir keinen (oder nur mit einem
          unverhältnismäßigen Aufwand) Bezug zu Ihrer Person herstellen können,
          z.B. durch Anonymisierung, sind keine personenbezogenen Daten. Die
          Verarbeitung von personenbezogenen Daten (bspw. das Erheben, das
          Abfragen, die Verwendung, die Speicherung oder die Übermittlung)
          bedarf immer einer gesetzlichen Grundlage und eines definierten
          Zwecks.
        </p>
        <p>
          Gespeicherte personenbezogene Daten werden gelöscht, sobald der Zweck
          der Verarbeitung erreicht wurde und es keine rechtmäßigen Gründe für
          eine weitere Aufbewahrung der Daten gibt. Wir informieren Sie in den
          einzelnen Verarbeitungsvorgängen über die konkreten Speicherfristen
          bzw. Kriterien für die Speicherung. Unabhängig davon, speichern wir
          Ihre personenbezogenen in Einzelfällen zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen und bei Vorliegen gesetzlicher
          Aufbewahrungspflichten.
        </p>

        <h2>Wer bekommt meine Daten?</h2>

        <p>
          Wir geben Ihre personenbezogenen Daten, die wir auf unserer Website
          verarbeiten nur dann an Dritte weiter, wenn dies für die Erfüllung der
          Zwecke erforderlich ist und im Einzelfall von der Rechtsgrundlage
          (z.B. Einwilligung oder Wahrung berechtigter Interessen) erfasst ist.
          Darüber hinaus geben wir im Einzelfall personenbezogene Daten an
          Dritte weiter, wenn dies der Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen dient. Mögliche Empfänger können
          dann z.B. Strafverfolgungsbehörden, Rechtsanwälte, Wirtschaftsprüfer,
          Gerichte usw. sein.
        </p>
        <p>
          Soweit wir für den Betrieb unserer Website Dienstleister einsetzen,
          die im Rahmen einer Auftragsverarbeitung in unserem Auftrag
          personenbezogene Daten gem. Art. 28 DSGVO verarbeiten, können diese
          Empfänger Ihrer personenbezogenen Daten sein. Nähere Informationen zum
          Einsatz von Auftragsverarbeitern sowie von Webdiensten erhalten Sie in
          der Übersicht der einzelnen Verarbeitungsvorgänge.
        </p>

        <h2>Setzen Sie Cookies ein?</h2>

        <p>
          Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer
          Internetseiten von uns an den Browser Ihres Endgeräts gesendet und
          dort gespeichert werden. Alternativ zum Einsatz von Cookies können
          Informationen auch im lokalen Speicher (local storage) Ihres Browsers
          gespeichert werden. Einige Funktionen unserer Internetseite können
          ohne den Einsatz von Cookies bzw. local storage nicht angeboten werden
          (technisch notwendige Cookies). Andere Cookies ermöglichen uns
          hingegen verschiedene Analysen, sodass wir beispielsweise in der Lage
          sind, den von Ihnen verwendeten Browser bei einem erneuten Besuch
          unserer Webseite wiederzuerkennen und verschiedene Informationen an
          uns zu übermitteln (nicht notwendige Cookies). Mithilfe von Cookies
          können wir unter anderem unser Internetangebot für Sie
          nutzerfreundlicher und effektiver gestalten, indem wir etwa Ihre
          Nutzung unserer Website nachvollziehen und Ihre bevorzugten
          Einstellungen (bspw. Länder- und Spracheneinstellungen) feststellen.
          Sofern Dritte über Cookies Informationen verarbeiten, erheben diese
          die Informationen direkt über Ihren Browser. Cookies richten auf Ihrem
          Endgerät keinen Schaden an. Sie können keine Programme ausführen und
          keine Viren enthalten.
        </p>
        <p>
          Über die jeweiligen Dienste, für die wir Cookies einsetzen,
          informieren wir in den einzelnen Verarbeitungsvorgängen. Ausführliche
          Informationen zu den eingesetzten Cookies finden Sie in den
          Cookie-Einstellungen oder im Consent Manager dieser Website.
        </p>

        <h2>Welche Rechte habe ich?</h2>

        <p>
          Unter den Voraussetzungen der gesetzlichen Vorschriften der
          Datenschutz-Grundverordnung (DSGVO) haben Sie als betroffene Person
          folgende Rechte:
        </p>
        <ul>
          <li>
            Auskunft gem. Art. 15 DSGVO über die zu Ihrer Person gespeicherten
            Daten in Form von aussagekräftigen Informationen zu den Einzelheiten
            der Verarbeitung sowie eine Kopie Ihrer Daten;
          </li>
          <li>
            Berichtigung gem. Art. 16 DSGVO von unrichtigen oder unvollständigen
            Daten, die bei uns gespeichert sind;
          </li>
          <li>
            Löschung gem. Art. 17 DSGVO der bei uns gespeicherten Daten, soweit
            die Verarbeitung nicht zur Ausübung des Rechts auf freie
            Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
            Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            erforderlich ist;
          </li>
          <li>
            Einschränkung der Verarbeitung gem. Art. 18 DSGVO, soweit die
            Richtigkeit der Daten bestritten wird, die Verarbeitung unrechtmäßig
            ist, wir die Daten nicht mehr benötigen und Sie deren Löschung
            ablehnen, weil Sie diese zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen benötigen oder Sie Widerspruch
            gegen die Verarbeitung gem. Art. 21 DSGVO erhoben haben.
          </li>
          <li>
            Datenübertragbarkeit gem. Art. 20 DSGVO, soweit Sie uns
            personenbezogene Daten im Rahmen einer Einwilligung gem. Art. 6 Abs.
            1 lit. a DSGVO oder auf Grundlage eines Vertrages gem. Art. 6 Abs. 1
            lit. b DSGVO bereitgestellt haben und diese durch uns mithilfe
            automatisierter Verfahren verarbeitet wurden. Sie erhalten Ihre
            Daten in einem strukturierten, gängigen und maschinenlesbaren Format
            bzw. wir übermitteln die Daten direkt an einen anderen
            Verantwortlichen, soweit dies technisch machbar ist.
          </li>
          <li>
            Widerspruch gem. Art. 21 DSGVO gegen die Verarbeitung Ihrer
            personenbezogenen Daten, soweit diese auf Grundlage des Art. 6 Abs.
            1 lit. e, f DSGVO erfolgt und dafür Gründe vorliegen, die sich aus
            Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen
            Direktwerbung richtet. Das Recht auf Widerspruch besteht nicht, wenn
            überwiegende, zwingende schutzwürdige Gründe für die Verarbeitung
            nachgewiesen werden oder die Verarbeitung zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen erfolgt. Soweit das
            Recht auf Widerspruch bei einzelnen Verarbeitungsvorgängen nicht
            besteht, ist dies dort angegeben.
          </li>
          <li>
            Widerruf gem. Art. 7 Abs. 3 DSGVO Ihrer erteilten Einwilligung mit
            Wirkung für die Zukunft.
          </li>
          <li>
            Beschwerde gem. Art. 77 DSGVO bei einer Aufsichtsbehörde, wenn Sie
            der Ansicht sind, die Verarbeitung Ihrer personenbezogenen Daten
            verstößt gegen die DSGVO. In der Regel können Sie sich an die
            Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres
            Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
          </li>
        </ul>

        <h2>Wie werden meine Daten im Einzelnen verarbeitet?</h2>

        <p>
          Nachfolgend informieren wir Sie über die einzelnen
          Verarbeitungsvorgänge, den Umfang und den Zweck der Datenverarbeitung,
          die Rechtsgrundlage, die Pflicht zur Bereitstellung Ihrer Daten und
          die jeweilige Speicherdauer. Eine automatisierte Entscheidung im
          Einzelfall, einschließlich Profiling findet nicht statt.
        </p>

        <h2>Informationen zum Datenschutz nach EU-DSGVO</h2>

        <p>
          Unser Unternehmen prüft regelmäßig bei Vertragsabschlüssen und in
          bestimmten Fällen, in denen ein berechtigtes Interesse vorliegt, auch
          bei Bestandskunden, Ihre Bonität. Dazu arbeiten wir mit der
          Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss zusammen,
          von der wir die dazu benötigten Daten erhalten. Zu diesem Zweck
          übermitteln wir Ihren Namen und Ihre Kontaktdaten an die Creditreform
          Boniversum GmbH. Die Informationen gem. Art. 14 der
          EU-Datenschutz-Grundverordnung zu der bei der Creditreform Boniversum
          GmbH stattfindenden Datenverarbeitung finden Sie hier:{' '}
          <a
            href="https://www.boniversum.de/eu-dsgvo/informationen-nach-eudsgvo-fuer-verbraucher"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://www.boniversum.de/eu-dsgvo/informationen-nach-eudsgvo-fuer-verbraucher
          </a>
        </p>

        <h2>Bereitstellung der Website</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Bei Aufruf und Nutzung unserer Website erheben wir die
          personenbezogenen Daten, die Ihr Browser automatisch an unseren Server
          übermittelt. Die folgenden Informationen werden temporär in einem sog.
          Logfile gespeichert:
        </p>
        <ul>
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>
            Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners,
            sowie der Name Ihres Access-Providers
          </li>
        </ul>
        <p>
          Unsere Website wird nicht von uns selbst gehostet, sondern bei einem
          Dienstleister, der für den Zweck der die zuvor genannten Daten in
          unserem Auftrag gem. Art. 28 DSGVO verarbeitet.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Verarbeitung erfolgt zur Wahrung unseres überwiegenden
          berechtigten Interesses zur Anzeige unserer Website und Gewährleistung
          der Sicherheit und Stabilität auf Grundlage des Art. 6 Abs. lit. f
          DSGVO. Die Erfassung der Daten und die Speicherung in Logfiles ist für
          den Betrieb der Website zwingend erforderlich. Ein Widerspruchsrecht
          gegen die Verarbeitung besteht aufgrund der Ausnahme nach Art. 21 Abs.
          1 DSGVO nicht. Soweit die weitergehende Speicherung der Logfiles
          gesetzlich vorgeschrieben ist, erfolgt die Verarbeitung auf Grundlage
          des Art. 6 Abs. 1 lit. c DSGVO. Es besteht keine gesetzliche oder
          vertragliche Pflicht zur Bereitstellung der Daten, allerdings ist der
          Aufruf unserer Website ohne Bereitstellung der Daten technisch nicht
          möglich.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die vorgenannten Daten werden für die Dauer der Anzeige der Website,
          sowie aus technischen Gründen, darüber hinaus für maximal 7 Tage
          gespeichert.
        </p>

        <h2>Kontaktformular</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Auf unserer Webseite bieten wir Ihnen an, über ein bereitgestelltes
          Formular mit uns in Kontakt zu treten. Die Informationen, die über
          Pflichtfelder erhoben werden, sind erforderlich um die Anfrage
          bearbeiten zu können. Darüber hinaus können Sie freiwillig zusätzliche
          Informationen bereitstellen, die aus Ihrer Sicht für die Bearbeitung
          der Kontaktanfrage notwendig sind.
        </p>
        <p>
          Bei der Nutzung des Kontaktformulars erfolgt keine Weitergabe Ihrer
          personenbezogenen Daten an Dritte.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Verarbeitung Ihrer Daten durch Nutzung unseres Kontaktformulars
          erfolgt zum Zweck der Kommunikation und Bearbeitung Ihrer Anfrage auf
          Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO. Soweit
          sich Ihre Anfrage auf ein bestehendes Vertragsverhältnis mit uns
          bezieht, erfolgt die Verarbeitung zum Zweck der Vertragserfüllung auf
          Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Es besteht keine gesetzliche
          oder vertragliche Pflicht zur Bereitstellung Ihrer Daten, jedoch ist
          die Bearbeitung Ihrer Anfrage ohne die Bereitstellung der
          Informationen der Pflichtfelder nicht möglich. Soweit Sie diese Daten
          nicht bereitstellen möchten, kontaktieren Sie uns bitte mit anderen
          Mitteln.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Soweit Sie das Kontaktformular auf Grundlage Ihrer Einwilligung
          verwenden, speichern wir die erhobenen Daten jeder Anfrage für die
          Dauer von drei Jahren, beginnend mit der Erledigung Ihrer Anfrage oder
          bis zum Widerruf Ihrer Einwilligung.
        </p>
        <p>
          Sollten Sie das Kontaktformular im Rahmen einer vertraglichen
          Beziehung nutzen, speichern wir die erhobenen Daten jeder Anfrage für
          die Dauer von bis zu sechs Jahren ab Ende des Vertragsverhältnisses.
        </p>

        <h2>Newsletter</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Sofern Sie sich auf unserer Website zum Empfang unseres Newsletters
          anmelden, erheben wir Ihre E-Mail-Adresse sowie Ihre weiteren Angaben
          und speichern diese Informationen zusammen mit dem Datum der Anmeldung
          und Ihrer IP-Adresse. Im Anschluss erhalten Sie eine E-Mail, in der
          Sie die Anmeldung zum Newsletter bestätigen müssen (Double-Opt-in).
          Sollten Sie nicht innerhalb von 24 Stunden die Anmeldung bestätigen,
          verfällt diese automatisch und die Daten werden nicht für den
          Newsletterversand verarbeitet.
        </p>
        <p>
          Zum Versand des Newsletters nutzen wir einen Service , der Ihre
          personenbezogenen Daten in unserem Auftrag gem. Art. 28 DSGVO
          verarbeiten. Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Wir verarbeiten Ihre Daten zum Zweck des Newsletterversands auf
          Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO. Durch
          Abmeldung vom Newsletter können Sie jederzeit mit Wirkung für die
          Zukunft Ihren Widerruf gem. Art. 7 Abs. 3 DSGVO erklären. Es besteht
          keine gesetzliche oder vertragliche Pflicht zur Bereitstellung Ihrer
          Daten, jedoch ist ein Versand des Newsletters ohne die Bereitstellung
          Ihrer Daten nicht möglich.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Nach Anmeldung zum Newsletter speichern wir die Daten maximal 24
          Stunden bis zur Bestätigung der Anmeldung. Nach erfolgreicher
          Bestätigung speichern wir Ihre Daten bis zum Widerruf Ihrer
          Einwilligung (Abmeldung vom Newsletter) sowie aus technischen Gründen
          darüber hinaus maximal 7 Tage.
        </p>

        <h2>Facebook Pixel</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Wir verwenden Facebook Pixel von Meta Platforms Ireland Limited, 4
          Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland, um
          sogenannte Custom Audiences zu erstellen, also Besuchergruppen unseres
          Onlineangebotes zu segmentieren, Conversion-Rates zu ermitteln und
          diese anschließend zu optimieren. Dies geschieht insbesondere dann,
          wenn Sie mit Werbeanzeigen, die wir mit Meta Platforms Ireland Limited
          geschaltet haben, interagieren.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Wir verarbeiten Ihre Daten mit Hilfe von Facebook Pixel zum Zweck der
          Optimierung unserer Website und für Marketingzwecke auf Grundlage
          Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Meta Platforms Ireland Limited
          bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für
          Facebook Pixel:{' '}
          <a
            href="https://www.facebook.com/privacy/explanation."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://www.facebook.com/privacy/explanation.
          </a>
        </p>

        <h2>Google CDN</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer
          Website Google CDN. Google CDN ist ein Dienst der Google Ireland
          Limited, welcher auf unserer Website als Content Delivery Network
          (CDN) fungiert.
        </p>
        <p>
          Ein CDN trägt dazu bei, Inhalte unseres Onlineangebotes, insbesondere
          Dateien wie Grafiken oder Skripte, mit Hilfe regional oder
          international verteilter Server schneller bereitzustellen. Wenn Sie
          auf diese Inhalte zugreifen, stellen Sie eine Verbindung zu Servern
          der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
          Irland her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr
          User-Agent übermittelt werden. Diese Daten werden ausschließlich zu
          den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit
          und Funktionalität von Google CDN verarbeitet.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Nutzung des Content Delivery Networks erfolgt auf Grundlage
          unserer berechtigten Interessen, d.h. Interesse an einer sicheren und
          effizienten Bereitstellung sowie der Optimierung unseres
          Onlineangebotes gemäß Art. 6 Abs. 1 lit. f. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Google Ireland Limited bestimmt.
          Weitere Hinweise finden Sie in der Datenschutzerklärung für Google
          CDN:{' '}
          <a
            href="https://policies.google.com/privacy."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://policies.google.com/privacy.
          </a>
        </p>

        <h2>Google Fonts</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Wir verwenden Google Fonts von Google Ireland Limited, Gordon House,
          Barrow Street, Dublin 4, Irland, als Dienst zur Bereitstellung von
          Schriftarten für unser Onlineangebot. Um diese Schriftarten zu
          beziehen, stellen Sie eine Verbindung zu Servern von Google Ireland
          Limited her, wobei Ihre IP-Adresse übertragen wird.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Nutzung von Google Fonts erfolgt auf Grundlage unserer
          berechtigten Interessen, d.h. Interesse an einer einheitlichen
          Bereitstellung sowie der Optimierung unseres Onlineangebotes gemäß
          Art. 6 Abs. 1 lit. f. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Google Ireland Limited bestimmt.
          Weitere Hinweise finden Sie in der Datenschutzerklärung für Google
          Fonts:{' '}
          <a
            href="https://policies.google.com/privacy."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://policies.google.com/privacy.
          </a>
        </p>

        <h2>Google Maps</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Wir verwenden zur Erstellung von Anfahrtsbeschreibungen den
          Kartendienst Google Maps. Google Maps ist ein Dienst der Google
          Ireland Limited, welcher auf unserer Website eine Karte darstellt.
        </p>
        <p>
          Wenn Sie auf diese Inhalte unserer Website zugreifen, stellen Sie eine
          Verbindung zu Servern der Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland her, wobei Ihre IP-Adresse und ggf.
          Browserdaten wie Ihr User-Agent übermittelt werden. Diese Daten werden
          ausschließlich zu den oben genannten Zwecken und zur Aufrechterhaltung
          der Sicherheit und Funktionalität von Google Maps verarbeitet.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Nutzung von Google Maps erfolgt auf Grundlage Ihrer Einwilligung
          gemäß Art. 6 Abs. 1 lit. a. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Google Ireland Limited bestimmt.
          Weitere Hinweise finden Sie in der Datenschutzerklärung für Google
          Maps:{' '}
          <a
            href="https://policies.google.com/privacy."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://policies.google.com/privacy.
          </a>
        </p>

        <h2>Wix</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Unsere Website wurde mit dem Website-Baukastensystem Wix erstellt. Wix
          ist ein Dienst der Wix.com, Inc und bietet Webentwicklung-Technologie,
          Webdesign- und Layout-Tools, Domain-Hosting und andere Anwendungen für
          Marketing und Workflow-Management an.
        </p>
        <p>
          Wir verwenden Wix unter anderem zum Webhosting und der Darstellung
          unserer Website. Darüber hinaus sammelt Wix statistische Daten über
          den Besuch unserer Website.
        </p>
        <p>
          Folgende Daten werden in der Regel übertragen: abgerufenen Website,
          Datum und Uhrzeit des Zugriffs, übertragene Datenmenge, Meldung ob ein
          Abruf erfolgreich war, Browsertyp und Browserversion, Betriebssystem
          des Nutzers, die zuvor besuchte Website (Referrer) und die IP-Adresse.
        </p>
        <p>
          Diese Protokolldaten werden ausschließlich zu den oben genannten
          Zwecken, sowie zur Aufrechterhaltung der Sicherheit, Funktionalität
          und der Optimierung des Angebots von Wix verarbeitet.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Nutzung des Dienstes erfolgt auf Grundlage unserer berechtigten
          Interessen, d.h. Interesse an einer sicheren und effizienten
          Bereitstellung, sowie der Optimierung unseres Onlineangebotes gemäß
          Art. 6 Abs. 1 lit. f. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Wix.com, Inc bestimmt. Weitere
          Hinweise finden Sie in der Datenschutzerklärung für Wix:{' '}
          <a
            href="https://de.wix.com/about/privacy."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://de.wix.com/about/privacy.
          </a>
        </p>

        <h2>Wix CDN</h2>

        <p>
          <u>Art und Umfang der Verarbeitung</u>
        </p>
        <p>
          Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer
          Website Wix CDN. Wix CDN ist ein Dienst der Wix.com, Inc, welcher auf
          unserer Website als Content Delivery Network (CDN) fungiert.
        </p>
        <p>
          Ein CDN trägt dazu bei, Inhalte unseres Onlineangebotes, insbesondere
          Dateien wie Grafiken oder Skripte, mit Hilfe regional oder
          international verteilter Server schneller bereitzustellen. Wenn Sie
          auf diese Inhalte zugreifen, stellen Sie eine Verbindung zu Servern
          der Wix.com, Inc, her, wobei Ihre IP-Adresse und ggf. Browserdaten wie
          Ihr User-Agent übermittelt werden. Diese Daten werden ausschließlich
          zu den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit
          und Funktionalität von Wix CDN verarbeitet.
        </p>
        <p>
          <u>Zweck und Rechtsgrundlage</u>
        </p>
        <p>
          Die Nutzung des Content Delivery Networks erfolgt auf Grundlage
          unserer berechtigten Interessen, d.h. Interesse an einer sicheren und
          effizienten Bereitstellung sowie der Optimierung unseres
          Onlineangebotes gemäß Art. 6 Abs. 1 lit. f. DSGVO.
        </p>
        <p>
          <u>Speicherdauer</u>
        </p>
        <p>
          Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
          beeinflussbar, sondern wird von Wix.com, Inc bestimmt. Weitere
          Hinweise finden Sie in der Datenschutzerklärung für Wix CDN:{' '}
          <a
            href="https://de.wix.com/about/privacy."
            target="_blank"
            rel="noreferrer noopener"
          >
            https://de.wix.com/about/privacy.
          </a>
        </p>
      </div>
    </StyledDiv>
  );
};
