import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
  position: absolute;
  right: 8.5px;
  top: 120px;
  z-index: 9;

  &.screenshot-button-mtk {
    right: 8.5px;
    top: 10px;
  }
`;
