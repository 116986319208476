import { MutableRefObject, useEffect, useState } from 'react';
import { ResizeObserver } from 'resize-observer';
import { ContentRect } from 'resize-observer/lib/ContentRect';

export const useResizeObserver = (
  ref: MutableRefObject<Element | null>,
): ContentRect | undefined => {
  const [dimensions, setDimensions] = useState<ContentRect>();
  useEffect(() => {
    const observeTarget = ref.current as Element;
    if (!observeTarget) return;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return (): void => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);
  return dimensions;
};
