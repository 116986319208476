import React, { FC } from 'react';
import { Input as AntdInput, InputProps as OriginalProps } from 'antd';
import { TestID } from '../../utils';

export type InputProps = OriginalProps;

const { Group, Search } = AntdInput;

export const CompoundedInput = {
  Group: Group,
  Search: Search,
};

export const Input: FC<InputProps> = ({ ...props }) => {
  return <AntdInput data-testid={TestID.Input} {...props} />;
};
