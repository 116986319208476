import React, { FC } from 'react';
import { Form as AntdForm, FormItemProps as OriginalProps } from 'antd';

const { Item: AntdFormItem } = AntdForm;

export type FormItemProps = OriginalProps;

export const FormItem: FC<FormItemProps> = ({ ...props }: FormItemProps) => {
  return <AntdFormItem {...props} />;
};
