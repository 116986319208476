import { Layout } from 'antd';
import { styled } from 'styled-components';

const { Content: PandoraContent } = Layout;

type layoutContentShadowProps = {
  showUserMenuOnly?: boolean;
};

export const LayoutWrapper = styled(Layout)`
  height: 100cqh;
  width: 100cqw;
  container-name: pandora-layout;
  container-type: size;
`;

export const MainAreaWrapper = styled(Layout)`
  overflow-y: auto;
  container-name: pandora-layout-content-layout;
  container-type: size;
`;

export const Content = styled(PandoraContent)<layoutContentShadowProps>`
  box-shadow: ${(props) =>
    props.showUserMenuOnly
      ? `inset 0px 0px 0px 0px ${props.theme.GRAY[400]}`
      : `inset 0px 0px 5px 0px ${props.theme.GRAY[400]}`};
  container-name: pandora-layout-content;
  container-type: inline-size;
  min-height: max-content;
  width: 100cqw;
`;

export const CopyrightText = styled.span`
  --color: ${({ theme }) => theme.PRIMARY.LIGHT};
  --hover-color: ${({ theme }) => theme.PRIMARY.LIGHTER};
  font-size: 14px;
  line-height: 26px;
  color: var(--color);

  & a {
    font-weight: 400;
    color: var(--color) !important;
  }

  & a:hover {
    color: var(--hover-color) !important;
  }
  & svg {
    width: 10px;
    height: 10px;
    margin-bottom: 5px;
    fill: var(--color);
    opacity: 0.5;
  }
`;

export const MenuRight = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;
