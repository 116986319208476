import { message as AntdMessage, ConfigProvider } from 'antd';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { createConfigTheme, mergeTheme, PALETTE } from '../themes/styles';
import { i18n } from '../utils';
import { PandoraContext } from './pandora.context';
import { IconContext } from '../icons/lib';
import { ThemeProvider } from 'styled-components';

export interface PandoraProviderProps {
  sidebarDefaultCollapsed?: boolean;
  navbarMenuKeyActiveDefault?: string;
  children: ReactNode;
  theme?: Partial<typeof PALETTE>;
  baseIconProps?: IconContext;
}

export const PandoraProvider: FC<PandoraProviderProps> = ({
  children,
  sidebarDefaultCollapsed = false,
  navbarMenuKeyActiveDefault = '',
  baseIconProps = {},
  theme = PALETTE,
}) => {
  const [messageApi, contextHolder] = AntdMessage.useMessage();
  const mergedTheme = useMemo(() => mergeTheme(theme), [theme]);

  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    sidebarDefaultCollapsed,
  );

  const [navbarMenuKeyActive, setNavbarMenuKeyActive] = useState<string>(
    navbarMenuKeyActiveDefault,
  );

  const [selectedMobileMenu, setSelectedMobileMenu] =
    useState<string>('default');

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={mergedTheme}>
        <ConfigProvider theme={createConfigTheme(mergedTheme)}>
          <PandoraContext.Provider
            value={{
              theme: mergedTheme,
              messageApi,
              sidebarCollapsed,
              setSidebarCollapsed,
              navbarMenuKeyActive,
              setNavbarMenuKeyActive,
              selectedMobileMenu,
              setSelectedMobileMenu,
            }}
          >
            <IconContext.Provider value={{ ...baseIconProps }}>
              {contextHolder}
              {children}
            </IconContext.Provider>
          </PandoraContext.Provider>
        </ConfigProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export const usePandoraContext = () => {
  const pandoraContext = React.useContext(PandoraContext);
  if (!pandoraContext) {
    throw new Error('usePandoraProvider must be used within a PandoraProvider');
  }
  return pandoraContext;
};
