import React, { FC } from 'react';
import { Modal as AntdModal, ModalProps as OriginalProps } from 'antd';
import { TestID } from '../../utils';

export type ModalProps = OriginalProps;

export const { confirm } = AntdModal;

export const Modal: FC<ModalProps> = ({ ...props }) => {
  return <AntdModal data-testid={TestID.Modal} {...props} />;
};
