import { LngLatLike } from 'mapbox-gl';
import { MarkerProps } from './../context/map/map.context';
import { shadeColor } from './shade-color';

export const DEFAULT_LANGUAGES = ['de', 'en', 'fr'];

export enum TestID {
  Accordion = 'pandora-accordion',
  Avatar = 'pandora-avatar',
  Button = 'pandora-button',
  Input = 'pandora-input',
  TextArea = 'pandora-text-area',
  Checkbox = 'pandora-checkbox',
  Search = 'pandora-search',
  Logo = 'pandora-iib-logo',
  Badge = 'pandora-badge',
  Card = 'pandora-card',
  Modal = 'pandora-modal',
  UserMenu = 'pandora-user-menu',
  AlternateMenuWrapper = 'pandora-alternate-nav-menu',
  Shimmer = 'pandora-shimmer',
  Repeat = 'pandora-repeat',
  Map = 'pandora-map',
  MapMTK = 'pandora-map-mtk',
  MapWrapperMTK = 'pandora-map-wrapper-mtk',
  MapCanvasMTK = 'pandora-map-canvas-mtk',
  ScreenshotButton = 'pandora-screenshot-button',
  ScreenshotOverlay = 'pandora-screenshot-overlay',

  AtlasLayout = 'pandora-atlas-layout',
  AtlasNavbar = 'pandora-atlas-navbar',
  AtlasSidebar = 'pandora-atlas-sidebar',
  AtlasContentDesktop = 'pandora-atlas-content-desktop',
  AtlasContentMobile = 'pandora-atlas-content-mobile',
  AtlasBottomBar = 'pandora-atlas-bottom-bar',
  AtlasTopBar = 'pandora-atlas-top-bar',

  H1 = 'pandora-heading-1',
  H2 = 'pandora-heading-2',
  H3 = 'pandora-heading-3',
  H4 = 'pandora-heading-4',
  H5 = 'pandora-heading-5',
  H6 = 'pandora-heading-6',
}

export const mockAccordionPanels = [
  {
    key: 'Angebote',
    header: 'Angebote',
    text: [
      '"Angebote" bezieht sich auf die durch das IIB Institut gesammelten Immobilieninserate der gängigsten deutschen Immobilienportale.',
      'Diese Angebote wurden von uns grundbereinigt und zeigen das aktuelle Marktgeschehen auf den Online-Marktplätzen.',
    ],
  },
  {
    key: 'Angebotsdichte',
    header: 'Angebotsdichte',
    text: [
      'Die "Angebotsdichte" beschreibt die Verfügbarkeit von Angebot je 1.000 Einwohner.',
      'Über den Vergleich der Dichte zwischen der Bundesrepublik, dem jeweiligen Bundesland und dem gewählten Landkreis, lässt sich abschätzen ob es zu einerlokalen Angebotsverknappung kommt oder ob verhältnismäßig viel Angebot im Markt gegeben ist.',
    ],
  },
];

export const DEFAULT_MAPBOX_TOKEN =
  'pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3Fhcnp1MmQwM2QxMnBubTRkbHRpb3BxIn0.LlUlH0p_XAW7d6wjTkU8ug';
export const DEFAULT_MAPBOX_STYLE =
  'mapbox://styles/iibmaps/ckromvmcq3up519o1au7nfo37';

export const PREECO_TOKEN =
  '0yzP4tojXaGrL5BhnD5BRjtIIw1a3NdzNfeluME5Tbc47cPccXQvVrz72LnMPuHj';

export const ATLAS_FALLBACK_COLOR_PRIMARY = '#B09046';
export const ATLAS_FALLBACK_COLOR_HOVER_PRIMARY = shadeColor(
  40,
  ATLAS_FALLBACK_COLOR_PRIMARY,
);

export const MarkersList: MarkerProps[] = [
  { location: [13.404954, 52.520008] as LngLatLike, id: 'marker-id-111' },
  { location: [13.404954, 52.5300008] as LngLatLike, id: 'marker-id-222' },
];
