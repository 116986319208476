import styled from 'styled-components';

export const MTKMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;
