import type { FC } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useScript } from 'usehooks-ts';

import * as S from './styles';

import './mtk.css';
import './mtk-ui.css';
import { MapHelper, TestID } from '../../utils';
import { MapContext } from '../../context';
import maplibregl from 'maplibre-gl';
import type { MapProps } from '../map';
import { ScreenshotButton } from '../internal';

interface MapState {
  center: [number, number];
  zoom: number;
  pitch?: number;
  bearing?: number;
  minZoom?: number;
  maxZoom?: number;
  maxBounds?: maplibregl.LngLatBoundsLike;
}

export type MapMTKProps = React.HTMLAttributes<HTMLDivElement> &
  Pick<
    MapProps,
    'mapId' | 'addMarkerAt' | 'allowScreenCapture' | 'attribution'
  > & {
    initialViewState?: Partial<MapState>;
    mapType?: string;
    styleControl?: boolean;
    zoomControl?: boolean;
  };

const BERLIN_CENTER: [number, number] = [13.404954, 52.520008];
const MAP_INITIAL_STATE: MapState = {
  center: BERLIN_CENTER,
  zoom: 20,
  pitch: 78,
  bearing: -35,
  minZoom: 6,
  maxZoom: 18,
};

export const MapMTK: FC<MapMTKProps> = ({
  mapId = 'map',
  mapType = 'toursprung-terrain',
  initialViewState,
  addMarkerAt,
  allowScreenCapture,
  attribution,
  styleControl = false,
  zoomControl = true,
  ...rest
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const markerRef = useRef<MTK.Marker>();
  const [canvasWidth, setCanvasWidth] = useState<number>();
  const [canvasHeight, setCanvasHeight] = useState<number>();
  const mapRef = useRef<MTK.Map>();

  const status = useScript('https://static.maptoolkit.net/mtk/v10.1.8/mtk.js', {
    removeOnUnmount: true,
  });

  const { map, setMap } = useContext(MapContext);

  useEffect(() => {
    if (status !== 'ready' || mapContainer.current === null) return;

    MTK.init({ apiKey: 'iib' });

    let existingMap = map?.[mapId];
    if (!existingMap) {
      existingMap = MTK.getMap(mapId);
    } else {
      existingMap = MapHelper.isMapMTK(existingMap) ? existingMap : undefined;
    }

    if (existingMap) {
      setMap?.(mapId, existingMap);
      mapRef.current = existingMap;
      mapContainer.current.replaceWith(existingMap.gl.getContainer()); // this is necessary to show existing map on remount
      return;
    }

    const mapControls: MTK.MapOptions['map']['controls'] = [];

    if (zoomControl) {
      mapControls.push(
        new maplibregl.NavigationControl({ showCompass: false }),
      );
    }

    if (attribution?.customAttribution) {
      mapControls.push([
        new maplibregl.AttributionControl({
          customAttribution: attribution.customAttribution,
          compact: attribution.compact,
        }),
        'bottom-right',
      ]);
    }

    if (styleControl) {
      mapControls.push([new MTK.StyleControl(), 'bottom-left']);
    }

    MTK.createMap(
      mapContainer.current,
      {
        map: {
          location: {
            ...MAP_INITIAL_STATE,
            ...initialViewState,
          },
          controls: [...mapControls],
          mapType,
          options: {
            attributionControl: false,
            preserveDrawingBuffer: true,
          },
        },
      },
      (map) => {
        MapHelper.getCanvasSize(map.gl.getCanvas(), (width, height) => {
          setCanvasWidth(width);
          setCanvasHeight(height);
        });

        map.isMTK = true;
        mapRef.current = map;
        setMap?.(mapId, map);
      },
    );
  }, [status]);

  useEffect(() => {
    if (!mapRef.current) return;

    MapHelper.getCanvasSize(mapRef.current?.gl.getCanvas(), (width, height) => {
      setCanvasWidth(width);
      setCanvasHeight(height);
    });
  }, [mapRef.current]);

  useEffect(() => {
    if (status !== 'ready' || !mapRef.current) return;

    const map = mapRef.current;
    if (markerRef.current) {
      markerRef.current.remove();
    }

    if (addMarkerAt) {
      const _marker = new MTK.Marker({})
        .setLngLat(addMarkerAt)
        .setImage({ id: 'marker', anchor: 'bottom' })
        .addTo(map);

      map.gl.flyTo({
        duration: 2000,
        center: addMarkerAt,
        zoom: 16,
        pitch: 0,
        bearing: 0,
      });

      markerRef.current = _marker;
    }
  }, [addMarkerAt, status, mapRef.current]);

  useEffect(() => {
    if (status !== 'ready' || !mapRef.current) return;
    const map = mapRef.current;
    if (map.mapType !== mapType) map.mapType = mapType;
  }, [mapType, status, mapRef.current]);

  return mapRef ? (
    <S.MTKMapWrapper data-testid={TestID.MapWrapperMTK} {...rest}>
      <S.MapContainer
        data-testid={TestID.MapMTK}
        ref={mapContainer}
        id={mapId}
      ></S.MapContainer>
      {allowScreenCapture && (
        <ScreenshotButton mapRef={mapRef} canvasId="mapCanvas" type="mtk" />
      )}

      <canvas
        data-testid={TestID.MapCanvasMTK}
        id="mapCanvas"
        hidden
        height={canvasHeight}
        width={canvasWidth}
      ></canvas>
    </S.MTKMapWrapper>
  ) : (
    <div> </div>
  );
};
