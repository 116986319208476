import React, { FC, ReactNode } from 'react';
import { Card as AntdCardExport } from 'antd';
import { CardProps as OriginalProps } from 'antd/es/card';
import { ShouldRender } from 'should-render';
import { TestID } from '../../utils';
import type { ContentStyle } from './card.styled';

import * as S from './card.styled';

export interface CardProps extends OriginalProps {
  /**  Card heading title. */
  cardTitle?: ReactNode;
  /** Title content */
  metaTitle?: ReactNode;
  /** Avatar or icon with the content. */
  avatar?: ReactNode;
  /** Content inside the card in the form of text. */
  description?: ReactNode;
  /** Content style */
  contentStyle?: ContentStyle;
}

export const { Meta, Grid } = AntdCardExport;

export const CompoundedCard = {
  Meta,
  Grid,
};

export const Card: FC<CardProps> = ({ cardTitle, contentStyle, ...props }) => {
  return (
    <S.StyledCard
      data-testid={TestID.Card}
      title={cardTitle}
      content-style={contentStyle || {}}
      {...props}
    >
      <ShouldRender if={props.avatar || props.metaTitle || props.description}>
        <Meta
          avatar={props.avatar}
          title={props.metaTitle}
          description={props.description}
        />
      </ShouldRender>
      {props.children}
    </S.StyledCard>
  );
};
