import styled from 'styled-components';

export const StyledDiv = styled.div`
  --color: ${({ theme }) => theme.GRAY[500]};
  --link-color: ${({ theme }) => theme.GRAY[600]};

  border-radius: 0px;
  color: var(--color);
  margin: 50px;
  a:link {
    color: var(--link-color);
    font-weight: 600;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: var(--link-color);
    font-weight: 600;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  }

  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }
`;
