import React, { FC, ReactNode } from 'react';
import {
  Collapse as AntdCollapse,
  CollapseProps,
  CollapsePanelProps,
} from 'antd';
import { TestID } from '../../utils';

import * as S from './accordion.styled';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { ExpandIconPosition } from 'antd/es/collapse/Collapse';
import { SizeType } from '../select';
import { AiOutlineRight } from '../../icons';

interface PanelProps {
  isActive?: boolean;
  header?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  showArrow?: boolean;
  forceRender?: boolean;
  extra?: React.ReactNode;
  collapsible?: CollapsibleType;
}

export interface AccordionProps extends Omit<CollapseProps, 'prefixCls'> {
  children?: ReactNode;
  activeKey?: Array<string | number> | string | number;
  defaultActiveKey?: Array<string | number> | string | number;
  /** 手风琴效果 */
  accordion?: boolean;
  destroyInactivePanel?: boolean;
  onChange?: (key: string | string[]) => void;
  style?: React.CSSProperties;
  className?: string;
  rootClassName?: string;
  bordered?: boolean;
  expandIcon?: (panelProps: PanelProps) => React.ReactNode;
  expandIconPosition?: ExpandIconPosition;
  ghost?: boolean;
  size?: SizeType;
  collapsible?: CollapsibleType;
}

export interface AccordionPanelProps extends CollapsePanelProps {
  children?: ReactNode;
}

const { Panel: CollapsePanel } = AntdCollapse;

const Panel: FC<AccordionPanelProps> = ({ children, ...props }) => {
  return <CollapsePanel {...props}>{children}</CollapsePanel>;
};

const AccordionComponent: FC<AccordionProps> = ({ children, ...props }) => {
  const Collapse =
    !props.bordered && !props.ghost ? S.CollapseUnbordered : S.Collapse;

  return (
    <div data-testid={TestID.Accordion}>
      <Collapse
        accordion
        bordered={false}
        expandIconPosition="right"
        expandIcon={({ isActive }: PanelProps) => (
          <S.ExpandIconWrapper rotate={isActive ? '90deg' : '0deg'}>
            <AiOutlineRight />
          </S.ExpandIconWrapper>
        )}
        {...props}
      >
        {children}
      </Collapse>
    </div>
  );
};

export const Accordion = Object.assign(AccordionComponent, {
  Panel,
});
