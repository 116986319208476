import React, { FC, ReactNode } from 'react';
import { Checkbox as AntdCheckbox, CheckboxProps as OriginalProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { MaybePromise, TestID } from '../../utils';

export interface CheckboxProps extends OriginalProps {
  children?: ReactNode;
  /** Specifies the initial state: whether or not the checkbox is selected */
  defaultChecked?: boolean;
  /** If disable checkbox */
  disabled?: boolean;
  /** The callback function that is triggered when the state changes */
  onChange?: (e: CheckboxChangeEvent) => MaybePromise<void>;
}

export const CheckboxGroup = AntdCheckbox.Group;

export const Checkbox: FC<CheckboxProps> = ({ children, ...props }) => {
  return (
    <AntdCheckbox data-testid={TestID.Checkbox} {...props}>
      {children}
    </AntdCheckbox>
  );
};
