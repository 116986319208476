import React, { FC, ReactNode } from 'react';
import { Badge as AntdBadge, BadgeProps as OriginalProps } from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { TestID } from '../../utils';

export interface BadgeProps extends OriginalProps {
  children?: ReactNode;
  /** Customize Badge dot color */
  color?: string;
  /** Number to show in badge */
  count?: ReactNode;
  /** Whether to display a red dot instead of count */
  dot?: boolean;
  /** Whether to show badge when count is zero */
  showZero?: boolean;
  /** Set Badge as a status dot	 */
  status?: PresetStatusColorType;
  /** Text to show when hovering over the badge */
  title?: string;
  /** If count is set, size sets the size of badge */
  size?: 'default' | 'small';
  /** If status is set, text sets the display text of the status dot */
  text?: ReactNode;
}

export interface BadgeRibbonProps extends OriginalProps {
  children?: ReactNode;
  /** Customize Ribbon colo */
  color?: string;
  /** The placement of the Ribbon, start and end follow text direction (RTL or LTR) */
  placement?: 'start' | 'end';
  /** Content inside the Ribbon */
  text?: ReactNode;
}

export const BadgeRibbon: FC<BadgeRibbonProps> = AntdBadge.Ribbon;

export const Badge: FC<BadgeProps> = ({ children, ...props }) => {
  return (
    <AntdBadge data-testid={TestID.Badge} {...props}>
      {children}
    </AntdBadge>
  );
};

Badge.defaultProps = {
  dot: false,
  showZero: false,
};
