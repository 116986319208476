import { Layout } from 'antd';
import styled from 'styled-components';
import { ATLAS_FALLBACK_COLOR_PRIMARY } from '../../utils';
import { Sidebar } from '../layout/sidebar';
import { Navbar } from '../layout';

const { Content: PandoraContent } = Layout;

type ColorProps = {
  ['data-color']?: string;
  ['data-hover-color']?: string;
};

export const LayoutWrapper = styled(Layout)`
  height: 100cqh;
  width: 100cqw;
  container-name: pandora-layout-atlas;
  container-type: size;

  --bottom-menu-height: 56px;
`;

export const MainAreaWrapper = styled(Layout)`
  container-name: pandora-layout-atlas-content-layout;
  container-type: size;
`;

export const Content = styled(PandoraContent)`
  box-shadow: inset 0px 0px 5px 0px ${({ theme }) => theme.GRAY[400]};
  width: 100cqw;
  container-name: pandora-layout-atlas-content;
  container-type: size;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.GRAY[100]};

  @container pandora-layout-atlas (max-width: 720px) {
    & {
      box-shadow: none;
    }
  }
`;

export const ContentMobile = styled(PandoraContent)`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100cqw;
  height: calc(100cqh - var(--bottom-menu-height));
  background-color: ${({ theme }) => theme.GRAY[100]};
  container-name: pandora-layout-atlas-content-mobile;
  container-type: size;
  overflow-y: auto;

  display: none;

  &.show {
    display: block;
  }

  @container pandora-layout-atlas (width > 719px) {
    &.show {
      display: none;
    }
  }
`;

export const TopBar = styled.span<ColorProps>`
  top: 0;
  width: 100cqw;
  height: 4px;
  z-index: 1102;
  position: absolute;
  background-color: ${({ 'data-color': color }) =>
    color || ATLAS_FALLBACK_COLOR_PRIMARY};
`;

export const Footnote = styled.div`
  right: 0;
  width: 128px;
  bottom: 16px;
  height: auto;
  display: flex;
  opacity: 0.9;
  z-index: 1;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  p {
    color: #333533;
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 4px;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 1.75;
  }

  img {
    padding: 4px 0px;
    width: 90px;
  }

  @container pandora-layout-atlas (width < 720px) {
    & {
      display: none;
    }
  }
`;

export const AtlasSidebar = styled(Sidebar)`
  @container (width < 720px) {
    & {
      display: none;
    }
  }
`;

export const AtlasNavbar = styled(Navbar)`
  height: max-content;
  min-height: 120px;

  @container pandora-layout-atlas (max-width: 720px) {
    & {
      box-shadow: none;
    }
  }

  &.hide-on-mobile {
    display: none;
  }
`;

export const BottomBar = styled.div`
  display: block;
  width: 100%;
  background-color: #242423;
  color: ${({ theme }) => theme.GRAY[400]};
  height: var(--bottom-menu-height);
  bottom: 0;
  position: absolute;
  z-index: 1102;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @container pandora-layout-atlas (width > 719px) {
    & {
      display: none;
    }
  }
`;

export const BottomBarItem = styled.div<ColorProps>`
  color: ${({ theme }) => theme.GRAY[400]};
  margin-inline: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 10px;
  height: 100%;

  & > svg {
    height: 24px;
    width: 24px;
  }

  &.active {
    color: ${({ 'data-color': color }) => color};
  }

  &:hover {
    color: ${({ 'data-hover-color': hoverColor }) => hoverColor};
  }
`;
