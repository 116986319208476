import { Layout } from 'antd';
import { styled } from 'styled-components';

const { Footer: AntdFooter } = Layout;

export const Footer = styled(AntdFooter)`
  --color: ${({ theme }) => theme.COMMON.GRAY.MAIN}
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  position: sticky;
  left: 0;
  bottom: 0;
  height: 48px;
  width: 100%;
  text-align: center;
  color: var(--color);
  box-shadow: 1px -1px 5px 0px ${({ theme }) => theme.GRAY[400]};
`;
