import React, { FC } from 'react';
import { Input as AntdInput } from 'antd';
import { SearchProps as OriginalProps } from 'antd/es/input';
import { TestID } from '../../utils';

const { Search: AntdSearch } = AntdInput;

export type SearchProps = OriginalProps;

export const Search: FC<SearchProps> = ({ ...props }) => {
  return <AntdSearch data-testid={TestID.Search} {...props} />;
};
