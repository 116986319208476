import React, { FC } from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as OriginalProps } from 'antd/es/input/index';
import { MaybePromise, TestID } from '../../utils';
import { SizeType } from 'antd/es/config-provider/SizeContext';

const { TextArea: AntdTextArea } = AntdInput;

declare const InputStatuses: readonly ['warning', 'error'];
export type InputStatus = (typeof InputStatuses)[number];

export interface TextAreaProps extends OriginalProps {
  /** Placeholder text in the text box */
  placeholder?: string;
  /** Select Size */
  size?: SizeType;
  /** If allow to remove input content with clear icon */
  allowClear?: boolean;
  /** Height autosize feature, can be set to true | false or an object { minRows?: 2, maxRows?: 6 } */
  autoSize?: boolean | object;
  /** Whether has border style */
  bordered?: boolean;
  /** The initial input content */
  defaultValue?: string;
  /** Number of expanded rows */
  rows?: number;
  /** The min length of the content */
  minLength?: number;
  /** The max length of the content */
  maxLength?: number;
  /** Whether show text count of the content */
  showCount?: boolean;
  /** The input content value */
  value?: string;
  /** Set validation status  */
  status?: InputStatus;
  /** Whether to disable or not  */
  disabled?: boolean;
  /** Set id to the element  */
  id?: string;
  /** The callback function that is triggered when Enter key is pressed */
  onPressEnter?: (e: unknown) => MaybePromise<void>;
  /** The callback function that is triggered when resize */
  onResize?: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => MaybePromise<void>;
}

export const TextArea: FC<TextAreaProps> = ({ ...props }) => {
  return <AntdTextArea data-testid={TestID.TextArea} {...props} />;
};
