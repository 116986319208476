import React, { useEffect, useState, FC, ReactNode } from 'react';
import { Button as AntdButton, ButtonProps as OriginalProps } from 'antd';
import { MaybePromise, TestID } from '../../utils';

export interface ButtonProps extends OriginalProps {
  children: ReactNode;
  /** The variant of button style. Defaults to primary */
  variant?: OriginalProps['type'] | 'secondary';
  /** Whether the button is currenlty in loading state. Defaults to false. */
  loading?: boolean;
  /** On suffixTid for testing the components. */
  suffixTid?: string;
  /** On click handler. */
  onClick?: () => MaybePromise<void>;
  /** Function to be called when loading starts */
  onLoadStart?: () => MaybePromise<void>;
  /** Function to be called when loading ends */
  onLoadEnd?: () => MaybePromise<void>;
  /** On mouse enter handler. */
  onMouseEnter?: () => MaybePromise<void>;
  /** On mouse leave handler. */
  onMouseLeave?: () => MaybePromise<void>;
}

export const ButtonGroup = AntdButton.Group;

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  loading = false,
  onLoadStart,
  onLoadEnd,
  children,
  suffixTid,
  ...props
}) => {
  const [load, setLoad] = useState<boolean>(loading);

  useEffect(() => {
    if (loading === load) return;
    if (!loading && load) {
      setLoad(false);
      if (onLoadEnd) onLoadEnd();
    }
    if (loading && !load) {
      setLoad(true);
      if (onLoadStart) onLoadStart();
    }
  }, [loading]);

  return (
    <AntdButton
      data-testid={suffixTid ? `${TestID.Button}-${suffixTid}` : TestID.Button}
      loading={loading}
      type={variant === 'secondary' ? 'default' : variant}
      {...props}
    >
      {children}
    </AntdButton>
  );
};
