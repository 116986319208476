import type { Marker } from 'mapbox-gl';
import type { MapType } from '../context';
import mapboxgl from 'mapbox-gl';
import { AddLayerOptions, MarkerProps, SourceWithId } from '../context';
import maplibregl, { SourceSpecification } from 'maplibre-gl';

export class MapHelper {
  private static getImageDimensions = (
    image: string,
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = image;

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  static getCanvasSize = async (
    canvas: HTMLCanvasElement,
    setFn: (width: number, height: number) => void,
  ) => {
    const base64Image = canvas.toDataURL('image/png');
    if (!base64Image) return;

    const dimensions: { width: number; height: number } =
      await MapHelper.getImageDimensions(base64Image);
    setFn(dimensions.width, dimensions.height);
  };

  static isMapMTK = (map: MapType): map is MTK.Map => {
    if (!map) return false;
    return 'isMTK' in map;
  };
  static isMapLoaded = (
    mapId: string,
    mapDictionary: Record<string, MapType>,
  ) => {
    const map = mapDictionary[mapId];
    if (!map) return false;
    if (MapHelper.isMapMTK(map)) {
      return map?.gl?.loaded() && map?.gl?.areTilesLoaded();
    }

    return map?.loaded() && map?.isStyleLoaded() && map?.areTilesLoaded();
  };

  static waitForMapToLoad = (
    mapId: string,
    mapDictionary: Record<string, MapType>,
  ) => {
    return new Promise<boolean>((resolve) => {
      const map = mapDictionary[mapId];
      if (!map) return resolve(false);
      if (MapHelper.isMapLoaded(mapId, mapDictionary)) {
        resolve(true);
      } else {
        if (MapHelper.isMapMTK(map)) {
          map.gl.once('load', () => {
            resolve(true);
          });
          map.gl.once('idle', () => {
            resolve(true);
          });

          return;
        }
        map?.once('idle', () => {
          resolve(true);
        });
      }
    });
  };

  static getMapOrFail = (
    mapId: string,
    mapDictionary: Record<string, MapType>,
  ) => {
    if (!mapId) {
      throw new Error('MapId empty!');
    }
    const map = mapDictionary[mapId];
    if (!map) {
      throw new Error(`Map with id ${mapId} not found!`);
    }
    return map;
  };

  static addSource = (
    mapId: string,
    { id, ...source }: SourceWithId,
    mapDictionary: Record<string, MapType>,
    setSourceDictionary: React.Dispatch<
      React.SetStateAction<Record<string, string[]>>
    >,
  ) => {
    const map = MapHelper.getMapOrFail(mapId, mapDictionary);

    if (MapHelper.isMapMTK(map)) {
      if (map?.gl.getSource(id)) {
        const layersUsingSource = map?.gl
          .getStyle()
          .layers.filter((layer) => 'source' in layer && layer.source === id);

        layersUsingSource.forEach((layer) => {
          map?.gl.removeLayer(layer.id);
        });
        map?.gl.removeSource(id);
      }

      map?.gl.addSource(id, source as SourceSpecification);

      setSourceDictionary((current) => {
        const existingSources = current[mapId] || [];
        return { ...current, [mapId]: [...existingSources, id] };
      });
      return;
    }

    if (map.getSource(id)) {
      const layersUsingSource = map
        .getStyle()
        .layers.filter((layer) => 'source' in layer && layer.source === id);

      layersUsingSource.forEach((layer) => {
        map.removeLayer(layer.id);
      });
      map.removeSource(id);
    }

    map.addSource(id, source as mapboxgl.AnySourceData);
    setSourceDictionary((current) => {
      const existingSources = current[mapId] || [];
      return { ...current, [mapId]: [...existingSources, id] };
    });
  };

  static removeSource = (
    mapId: string,
    sourceId: string,
    mapDictionary: Record<string, MapType>,
    setSourceDictionary: React.Dispatch<
      React.SetStateAction<Record<string, string[]>>
    >,
  ) => {
    const map = MapHelper.getMapOrFail(mapId, mapDictionary);
    if (MapHelper.isMapMTK(map)) {
      if (!map?.gl.getSource(sourceId)) {
        return;
      }

      map?.gl.removeSource(sourceId);
      setSourceDictionary((current) => {
        const updatedSources = (current[mapId] || []).filter(
          (id) => id !== sourceId,
        );
        return { ...current, [mapId]: updatedSources };
      });
      return;
    }

    if (!map.getSource(sourceId)) {
      return;
    }

    map.removeSource(sourceId);
    setSourceDictionary((current) => {
      const updatedSources = (current[mapId] || []).filter(
        (id) => id !== sourceId,
      );
      return { ...current, [mapId]: updatedSources };
    });
  };

  static addLayer = (
    mapId: string,
    { before = undefined, onTop = false, ...layer }: AddLayerOptions,
    mapDictionary: Record<string, MapType>,
    setLayerDictionary: React.Dispatch<
      React.SetStateAction<Record<string, string[]>>
    >,
  ) => {
    const map = MapHelper.getMapOrFail(mapId, mapDictionary);

    if (MapHelper.isMapMTK(map)) {
      if (map?.gl.getLayer(layer.id)) {
        map?.gl.removeLayer(layer.id);
      }
      map?.gl.addLayer?.(layer, before);

      if (onTop) map?.gl.moveLayer(layer.id);
      setLayerDictionary((current) => {
        const existingLayers = current[mapId] || [];
        return { ...current, [mapId]: [...existingLayers, layer.id] };
      });
      return;
    }

    if (map.getLayer(layer.id)) {
      map.removeLayer(layer.id);
    }

    map.addLayer(layer, before);
    if (onTop) map.moveLayer(layer.id);

    setLayerDictionary((current) => {
      const existingLayers = current[mapId] || [];
      return { ...current, [mapId]: [...existingLayers, layer.id] };
    });
  };

  static removeLayer = (
    mapId: string,
    layerId: string,
    mapDictionary: Record<string, MapType>,
    setLayerDictionary: React.Dispatch<
      React.SetStateAction<Record<string, string[]>>
    >,
  ) => {
    const map = MapHelper.getMapOrFail(mapId, mapDictionary);

    if (MapHelper.isMapMTK(map)) {
      if (!map?.gl.getLayer(layerId)) {
        return;
      }
      map?.gl.removeLayer(layerId);
      setLayerDictionary((current) => {
        const updatedLayers = (current[mapId] || []).filter(
          (id) => id !== layerId,
        );
        return { ...current, [mapId]: updatedLayers };
      });
      return;
    }

    if (!map.getLayer(layerId)) {
      return;
    }

    map.removeLayer(layerId);
    setLayerDictionary((current) => {
      const updatedLayers = (current[mapId] || []).filter(
        (id) => id !== layerId,
      );
      return { ...current, [mapId]: updatedLayers };
    });
  };

  static addMarker = (
    mapId: string,
    { fitToMarkerBounds = false, location, id, ...options }: MarkerProps,
    mapDictionary: Record<string, MapType>,
    setMarkers: React.Dispatch<
      React.SetStateAction<Record<string, Marker | MTK.Marker>>
    >,
  ) => {
    if (!id) throw new Error('Error in addMarker: marker id empty!');

    const map = MapHelper.getMapOrFail(mapId, mapDictionary);

    if (MapHelper.isMapMTK(map)) {
      const marker = new MTK.LabeledMarker({
        ...options.options,
      })
        .setLngLat(location)
        .setImage({ id: 'marker', anchor: 'bottom' })
        .addTo(map);

      if (options.onClick) {
        marker.on('click', options.onClick);
      }

      marker.isMTK = true;

      if (fitToMarkerBounds) {
        map?.gl.fitBounds(
          [location, location] as unknown as maplibregl.LngLatBounds,
          {
            maxZoom: 12,
            duration: 2000,
          },
        );
      }
      setMarkers((current) => {
        const exists = current[id] || current[`${location[0]}-${location[1]}`];
        if (exists) {
          exists.remove();
        }
        const lngLatId = `${location[0]}-${location[1]}`;
        const updated = {
          ...current,
          [id]: marker,
          [lngLatId]: marker,
        };
        return updated;
      });
      return marker;
    }

    if (fitToMarkerBounds) {
      map.fitBounds(new mapboxgl.LngLatBounds(location, location), {
        maxZoom: 18,
        duration: 2000,
      });
    }

    const marker = new mapboxgl.Marker(options);
    marker.setLngLat(location);
    marker.addTo(map);

    setMarkers((current) => {
      const exists = current[id] || current[`${location[0]}-${location[1]}`];
      if (exists) {
        exists.remove();
      }
      const lngLatId = `${location[0]}-${location[1]}`;
      const updated = { ...current, [id]: marker, [lngLatId]: marker };
      return updated;
    });

    return marker;
  };

  static removeMarker = (
    markerId: string,
    setMarkers: React.Dispatch<
      React.SetStateAction<Record<string, Marker | MTK.Marker>>
    >,
  ) => {
    if (!markerId) throw new Error('Error in removeMarker: marker id empty!');

    setMarkers((current) => {
      const marker = current[markerId];
      if (marker) {
        marker.remove();
      }
      const updated = { ...current };
      delete updated[markerId];
      if (marker) {
        if ('isMTK' in marker) {
          const keys = Object.keys(marker);
          const lntLagPosition = keys.findIndex((elem) => elem === 'lngLat');
          const lngLatValue = Object.values(marker)[lntLagPosition] as any;
          //deleting the key:value [lng-lat] from markersDictionary
          delete updated[`${lngLatValue[0]}-${lngLatValue[1]}`];
        } else {
          delete updated[`${marker.getLngLat().lng}-${marker.getLngLat().lat}`];
        }
      }

      return updated;
    });

    return;
  };
}
