import { Layout } from 'antd';
import { styled } from 'styled-components';
import { CopyrightText } from '../layout.styled';

const { Sider: AntdSider } = Layout;

export const Sider = styled(AntdSider)`
  --color: ${({ theme }) => theme.COMMON.GRAY.MAIN};
  --background-color: ${({ theme }) => theme.PRIMARY.MAIN};
  --trigger-background-color: ${({ theme }) => theme.COMMON.WHITE};
  z-index: 19;
  border-right: 1px solid #dfe3e8;
  color: var(--color);
  height: 100cqh;
  container-name: pandora-sidebar;
  container-type: size;

  & > {
    color: var(--color);
  }
  & > .ant-layout-sider-trigger {
    background-color: var(--background-color);
  }

  & > .ant-layout-sider-zero-width-trigger {
    position: absolute;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    right: -15px;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background-color: var(--trigger-background-color);
    color: var(--background-color);
    box-shadow: -1px 1px 5px 1px ${({ theme }) => theme.GRAY[400]};

    &:hover {
      background-color: var(--background-color);
      color: var(--trigger-background-color);
    }
  }

  & > .ant-layout-sider-trigger {
    position: absolute;
    border-radius: 50%;
    width: 25px !important;
    height: 25px !important;
    right: -12.5px;
    top: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900 !important;
    font-size: 12px !important;
    background-color: var(--trigger-background-color);
    color: var(--background-color);
    box-shadow: -1px 0px 6px 1px ${({ theme }) => theme.GRAY[400]};

    &:hover {
      background-color: var(--background-color);
      color: var(--trigger-background-color);
    }

    & svg {
      stroke-width: 2px;
    }
  }

  @media screen and (max-width: 1099px) {
    &.ant-layout-sider-collapsed {
      width: 50px !important;
      max-width: 50px !important;
      min-width: 50px !important;
    }
  }

  @media screen and (max-width: 767px) {
    &.collapsible {
      position: fixed !important; //Stays on side with fill height on window scroll, position: absolute does not.
    }
    height: 100vh !important;

    &.ant-layout-sider-collapsed {
      width: 0px !important;
      max-width: 0px !important;
      min-width: 0px !important;

      & > .ant-layout-sider-trigger {
        top: 110px;
        right: -28px;
        width: 50px !important;
        height: 50px !important;

        & svg {
          height: 20px;
          width: 20px;
          margin-left: 20px;
          stroke-width: 8px;
        }
      }

      & .sidebar-logo {
        display: none;
      }
    }
  }
`;

export const Content = styled.section`
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
`;

export const ExternalLinks = styled.span`
  --color: ${({ theme }) => theme.PRIMARY.LIGHT};
  --hover-color: ${({ theme }) => theme.PRIMARY.LIGHTER};

  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 70px;
  margin-left: 20px;
  color: $var(--color);

  & a {
    font-size: 12px;
    font-weight: 200;
    padding-bottom: 3px;
    color: $var(--color) !important;
  }

  & a:hover {
    color: var(--hover-color) !important;
  }
  & svg {
    width: 10px;
    height: 10px;
    margin-bottom: 5px;
    fill: $var(--color);
    opacity: 0.5;
  }
`;

export const SidebarFooter = styled(CopyrightText)<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 48px;
  width: ${(props) => props.width};
  text-align: center;
  border-top: 1px solid #dfe3e8;
`;
