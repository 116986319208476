import { MessageInstance } from 'antd/es/message/interface';
import { Dispatch, SetStateAction, createContext } from 'react';
import type { PALETTE } from '../themes';

type PandoraContextType = {
  messageApi?: MessageInstance;
  sidebarCollapsed?: boolean;
  setSidebarCollapsed?: Dispatch<SetStateAction<boolean>>;
  navbarMenuKeyActive?: string;
  setNavbarMenuKeyActive?: Dispatch<SetStateAction<string>>;
  selectedMobileMenu?: string;
  setSelectedMobileMenu?: Dispatch<SetStateAction<string>>;
  theme?: typeof PALETTE;
};
export const PandoraContext = createContext<PandoraContextType>({
  messageApi: undefined,
  setSidebarCollapsed: undefined,
  sidebarCollapsed: undefined,
  navbarMenuKeyActive: undefined,
  setNavbarMenuKeyActive: undefined,
  selectedMobileMenu: undefined,
  setSelectedMobileMenu: undefined,
});
