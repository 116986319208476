import { Menu as AntdMenu, Layout } from 'antd';
import { styled } from 'styled-components';

const { Header: AntdHeader } = Layout;

type NavbarStyledProps = {
  height: string;
};
export const Header = styled(AntdHeader)<NavbarStyledProps>`
  width: 100%;
  position: relative;
  padding-inline: 0;
  box-shadow: 0px 2px 5px 0px ${({ theme }) => theme.GRAY[400]};
  height: ${(props: NavbarStyledProps) => props.height};
`;

export const MenuForLayout = styled(AntdMenu)<NavbarStyledProps>`
  height: inherit;
  max-height: ${(props: NavbarStyledProps) => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightMenu = styled(MenuForLayout)`
  border-bottom: none !important;
  & .ant-menu-item::after {
    border-bottom: none !important;
  }
`;

type NavWrapperProps = {
  gap?: string | number;
};

export const NavbarWrapper = styled.div<NavWrapperProps>`
  --background-color: ${({ theme }) => theme.BACKGROUND.PAPER};
  width: 100cqw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--background-color);
  padding: ${(props: NavWrapperProps) =>
    props?.gap ? `0px ${props.gap}` : 'unset'};
  overflow-y: visible;
`;

export const NavbarMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div<{ addPadding: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &:hover {
    & > .nav-menu-left-inner {
      margin-bottom: ${(props) => (props.addPadding ? '-5px' : 'unset')};
    }

    &::-webkit-scrollbar {
      height: 5px;
    }
  }

  &::-webkit-scrollbar {
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.PRIMARY.MAIN};
    opacity: 0.5;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #33333300;
  }
`;

export const LeftInner = styled.div<NavbarStyledProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${(props: NavbarStyledProps) => props.height};
  min-width: max-content;
`;

export const Right = styled.div`
  display: flex;
  height: inherit;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AlternateMenuWrapper = styled.div``;
