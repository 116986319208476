import { createContext } from 'react';
import mapboxgl, { LngLatLike, MarkerOptions } from 'mapbox-gl';
import { LayerSpecification, SourceSpecification } from 'maplibre-gl';

export type MTKMap = MTK.Map;
export type MapType = mapboxgl.Map | MTK.Map;
export type MarkerProps = MarkerOptions & {
  location: LngLatLike;
  id: string;
  fitToMarkerBounds?: boolean;
  options?: any; // TODO: Add MTK LabelledMarkerOptions type
  onClick?: (e: mapboxgl.MapboxEvent) => void;
};

export type SourceWithId = mapboxgl.AnySourceData &
  SourceSpecification & {
    id: string;
  };

export type AddLayerOptions = mapboxgl.AnyLayer &
  LayerSpecification & {
    before?: string;
    onTop?: boolean;
  };

export type MapContextType = {
  map?: Record<string, MapType>;
  setMap?: (mapId: string, map: MapType) => void;
  addMarker?: (
    mapId: string,
    options: MarkerProps,
  ) => mapboxgl.Marker | MTK.Marker | undefined;
  removeMarker?: (markerId: string) => void;
  markersDictionary?: Record<string, mapboxgl.Marker | MTK.Marker>;
  addLayer?: (mapId: string, layer: AddLayerOptions) => void;
  removeLayer?: (mapId: string, layerId: string) => void;
  addSource?: (mapId: string, source: SourceWithId) => void;
  removeSource?: (mapId: string, sourceId: string) => void;
  fitToBounds?: (
    mapId: string,
    bounds: mapboxgl.LngLatBounds,
    options?: mapboxgl.FitBoundsOptions,
  ) => void;
  isMapLoaded: (mapId: string) => boolean;
  waitForMapToLoad: (mapId: string) => Promise<boolean>;
};
export const MapContext = createContext<MapContextType>({
  map: undefined,
  setMap: undefined,
  isMapLoaded: () => false,
  addMarker: undefined,
  removeMarker: undefined,
  addLayer: undefined,
  removeLayer: undefined,
  addSource: undefined,
  removeSource: undefined,
  fitToBounds: undefined,
  waitForMapToLoad: () => Promise.resolve(false),
});
