import React, { FC, ReactNode, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import type { Marker } from 'mapbox-gl';
import {
  AddLayerOptions,
  MapContext,
  MarkerProps,
  SourceWithId,
  MapType,
} from './map.context';
import { MapHelper } from '../../utils';

export interface MapProviderProps {
  children: ReactNode;
}

export const MapProvider: FC<MapProviderProps> = ({ children }) => {
  const [mapDictionary, setMapDictionary] = useState<Record<string, MapType>>(
    {},
  );
  const [markersDictionary, setMarkers] = useState<
    Record<string, Marker | MTK.Marker>
  >({});
  const [, setLayerDictionary] = useState<Record<string, string[]>>({});
  const [, setSourceDictionary] = useState<Record<string, string[]>>({});

  const isMapLoaded = (mapId: string) =>
    MapHelper.isMapLoaded(mapId, mapDictionary);

  const waitForMapToLoad: (mapId: string) => Promise<boolean> = (
    mapId: string,
  ) => MapHelper.waitForMapToLoad(mapId, mapDictionary);

  const fitToBounds = (
    mapId: string,
    bounds: mapboxgl.LngLatBounds,
    options?: mapboxgl.FitBoundsOptions,
  ) => {
    const map = mapDictionary[mapId];
    if (MapHelper.isMapMTK(map)) {
      console.log('fitToBounds not implemented for MTK maps');
      return;
    }
    map?.fitBounds(bounds, { maxZoom: 18, duration: 2000, ...options });
  };

  const addSource = (mapId: string, { id, ...source }: SourceWithId) => {
    MapHelper.addSource(
      mapId,
      { id, ...source },
      mapDictionary,
      setSourceDictionary,
    );
  };

  const removeSource = (mapId: string, sourceId: string) => {
    MapHelper.removeSource(mapId, sourceId, mapDictionary, setSourceDictionary);
  };

  const addLayer = (
    mapId: string,
    { before, onTop, ...layer }: AddLayerOptions,
  ) => {
    MapHelper.addLayer(
      mapId,
      { before, onTop, ...layer },
      mapDictionary,
      setLayerDictionary,
    );
  };

  const removeLayer = (mapId: string, layerId: string) => {
    MapHelper.removeLayer(mapId, layerId, mapDictionary, setLayerDictionary);
  };

  const addMarker = (
    mapId: string,
    { fitToMarkerBounds = false, location, id, ...options }: MarkerProps,
  ) => {
    return MapHelper.addMarker(
      mapId,
      { fitToMarkerBounds, location, id, ...options },
      mapDictionary,
      setMarkers,
    );
  };

  const removeMarker = (markerId: string) => {
    MapHelper.removeMarker(markerId, setMarkers);
  };

  const setMap = (mapId: string, map: MapType) => {
    setMapDictionary((current) => ({ ...current, ...{ [mapId]: map } }));
  };

  return (
    <MapContext.Provider
      value={{
        map: mapDictionary,
        setMap,
        isMapLoaded,
        waitForMapToLoad,
        addMarker,
        removeMarker,
        markersDictionary,
        addLayer,
        removeLayer,
        addSource,
        removeSource,
        fitToBounds,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
