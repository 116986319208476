import React, { FC, ReactNode } from 'react';
import { Form as AntdForm, FormProps as OriginalProps } from 'antd';

export interface FormProps extends Omit<OriginalProps, 'children'> {
  children?: ReactNode;
}

export const { Item, List, Provider } = AntdForm;

export const CompoundedForm = {
  List,
  Provider,
};

export const Form: FC<FormProps> = ({ children, ...props }) => {
  return <AntdForm {...props}>{children}</AntdForm>;
};

export * from './item';
